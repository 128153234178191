import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAddBandAvailability } from '../useMutation';

const AvailModal = ({ closeAvailModal, thisBand, refetch }) => {
    const [date, setDate] = useState(new Date());
    const [addBandAvailability] = useAddBandAvailability();

    const handleAvailSubmit = (evt) => {
        evt.preventDefault();
        addNewAvailability();
    };

    const addNewAvailability = () => {
        addBandAvailability({
            variables: {
                date,
                band: thisBand,
            },
        });
    };
    return (
        <div className="modal-box">
            <div className="modal-content">
                <span
                    className="close"
                    onClick={() => {
                        closeAvailModal();
                        refetch();
                    }}
                >
                    &times;
                </span>
                <div className="modalTitle">Add a date</div>
                <div>
                    <form className="d-flex flex-column">
                        <label className="mt-2">
                            <span className="labelText">Date:</span>
                            <DatePicker selected={date} onChange={(date) => setDate(date)} />
                        </label>
                        <button type="submit" value="Submit" className="mt-2 mx-auto" id="go-button" onClick={handleAvailSubmit}>
                            Add Dates
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AvailModal;
