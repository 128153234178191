import React, { useState } from 'react';
import ShowsModal from '../../modals/components/ShowsModal';
import AvailModal from '../../modals/components/AvailModal';
import EditShowModal from '../../modals/components/EditShowModal';
import { useHistory } from 'react-router-dom';
import { Image, Placeholder } from 'cloudinary-react';
import { useAllVenuesQuery, useBandPageQuery, useOtherBandsQuery } from '../useQuery';
import LeftProfBar from '../../leftBar/components/LeftProfBar';

const Home = (props) => {
    let [showsModalOpen, setShowsModalOpen] = useState(false);
    let [availModalOpen, setAvailModalOpen] = useState(false);
    let [editShowModal, setEditShowModal] = useState(false);
    let [editShowData, setEditShowData] = useState({
        where: '',
        invitedBandId: '',
        showVenue: '',
        showDate: ''
    })

    let history = useHistory();

    let { data, loading, error, refetch } = useBandPageQuery();

    let {
        data: otherBands,
        loading: otherBandsLoading,
        error: otherBandsError,
    } = useOtherBandsQuery();

    let { data: theVenues, loading: theVenuesLoading, error: theVenuesError } = useAllVenuesQuery();

    if (loading || otherBandsLoading || theVenuesLoading) {
        return <p>Loading...</p>;
    }

    if (error || otherBandsError || theVenuesError) {
        return <p>Error!</p>;
    }

    let myBand;
    let otherBandsData;
    let venues;
    if (data && data.myBands) {
        myBand = data.myBands[0];
    }

    if (otherBands && otherBands.bands) {
        otherBandsData = otherBands.bands;
    }
    if (theVenues && theVenues.venues) {
        venues = theVenues.venues;
    }

    const openEditShowModal = (where, ticketID, venue, date) => {
        setEditShowData({
            where,
            invitedBandId: ticketID,
            showVenue: venue,
            showDate: date
        });
        setEditShowModal(true);
    };

    return (
        <div className="container-fluid padding-0 profile-container">
            <div className="profile-wrapper no-gutters">
                {showsModalOpen ? (
                    <ShowsModal
                        closeShowsModal={() => setShowsModalOpen(false)}
                        bandsToSelect={otherBandsData}
                        venuesToSelect={venues}
                        refetch={refetch}
                        myBandId={myBand.id}
                    />
                ) : null}
                {availModalOpen ? (
                    <AvailModal
                        closeAvailModal={() => setAvailModalOpen(false)}
                        thisBand={myBand.id}
                        refetch={refetch}
                    />
                ) : null}
                {editShowModal ? (
                    <EditShowModal
                        closeEditShowModal={() => setEditShowModal(false)}
                        editShowData={editShowData}
                        myBandId={myBand.id}
                        venuesToSelect={venues}
                        bandsToSelect={otherBandsData}
                        refetch={refetch}
                     />
                ) : null}
                <div className="profile-wrapper__left padding-0">
                    <LeftProfBar
                        openShowsModal={() => setShowsModalOpen(true)}
                        openAvailModal={() => setAvailModalOpen(true)}
                        openEditShowModal={openEditShowModal}
                        shows={myBand.shows}
                        refetch={refetch}
                        availability={myBand.availability}
                        requests={myBand.requests}
                        myId={myBand.id}
                    />
                </div>
                <div className="profile-wrapper__right padding-0">
                    <div id="carousel">
                        <Image
                            cloudName="dlhbtxwlg"
                            publicId={myBand.imageData[0].publicId}
                            loading="lazy"
                            height="100%"
                            width="100%"
                        >
                            <Placeholder type="pixelate" />
                        </Image>
                    </div>
                    <div id="edit-hiatus-buttons" className="row no-gutters">
                        <button className="col font-weight-bold" onClick={() => history.push('/edit')}>
                            Edit
                        </button>
                        <button className="col font-weight-bold">Go on hiatus</button>
                    </div>
                    <div id="info-ribbon" className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col">
                                <div id="prof-band-name">{myBand.name}</div>
                                <div>
                                    <span id="band-location">{myBand.zipCode}</span>
                                </div>
                            </div>
                            <div className="col">
                                <div id="genre-box">
                                    {myBand.genres.map((genre, i) => {
                                        return <div key={i}>{genre.name}</div>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" id="bottom-pane">
                        <div className="row py-2">
                            <div className="col">
                                <div>
                                    <span className="subtitle">Socials</span>
                                    {myBand.socialLinks.map((link, i) => {
                                        return (
                                            <div key={i}>
                                                <a href={link.url}>{link.url}</a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <div className="subtitle">Contact</div>
                                    {myBand.email}
                                </div>
                            </div>
                            <div className="col">
                                <div className="subtitle">Sounds</div>
                                <div>
                                    {myBand.audioLinks.map((audioLink, i) => {
                                        return (
                                            <div key={i}>
                                                <a href={audioLink.url}>{audioLink.url}</a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <span id="about-text">About us:&nbsp;</span>
                                    {myBand.description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
