import React from 'react';
import ConReq from './ConRequest';

const ConnectRequests = ({ requests, myId }) => {
    return (
        <div className="container">
            <div className="col">
                <div className="row" id="section-header">
                    Connection Requests
                </div>
                {Array.isArray(requests) ? (
                    <div>
                        {requests.map((request) => {
                            return (
                                <div className="row">
                                    {!request.hasResponse ? (
                                        <ConReq request={request} myId={myId} />
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="row">You have no requests</div>
                )}
            </div>
        </div>
    );
};

export default ConnectRequests;
