import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './components/AppContainer';
import Login from './shared/SignupLogin/components/SignupLogin';
import reportWebVitals from './reportWebVitals';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import decode from 'jwt-decode';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './styles/index.scss';
import Home from './business/home/components/Home';
// import DiscoverSearch from './components/Discover';
import DiscoverSearch from './components/NewDiscover';
import Connections from './business/connections/components/Connections';
import CreateFormNew from './business/band/components/CreateFormNew';

const serverURI = 'https://feb20stagedserv.herokuapp.com/';

const client = new ApolloClient({
    request: (operation) => {
        const token = localStorage.getItem('token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        });
    },
    uri: serverURI,
});

const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    // const refreshToken = localStorage.getItem('refreshToken');
    try {
        decode(token);
        // decode(refreshToken);
    } catch (err) {
        return false;
    }

    return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

function MainRouter() {
    return (
        <ApolloProvider client={client}>
            <Router>
                <AppContainer>
                    <Switch>
                        <Redirect from exact={true} path="/" to="/login" />
                        <PrivateRoute path="/home" component={Home} />
                        <PrivateRoute path="/create" component={CreateFormNew} />
                        <PrivateRoute path="/edit" component={CreateFormNew} />
                        <PrivateRoute path="/discover" component={DiscoverSearch} />
                        <PrivateRoute path="/connections" component={Connections} />
                        <Route path="/login" component={Login} />
                    </Switch>
                </AppContainer>
            </Router>
        </ApolloProvider>
    );
}

ReactDOM.render(<MainRouter />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
