import React from 'react';
import NavbarWithRouter from './Navbar';
import Footer from './Footer';

const AppContainer = ({ children }) => {
    const navbarName = (params) => {
        let title;
        switch (params) {
            case '/edit':
                title = 'Profile - edit';
                break;
            case '/welcome':
                title = 'Welcome';
                break;
            case '/calendar':
                title = 'Calendar';
                break;
            case '/discover':
                title = 'Discover';
                break;
            case '/login':
                title = 'Sign in';
                break;
            case '/profile':
                title = 'Profile';
                break;
            case '/notifications':
                title = 'Notifications';
                break;
            case '/connections':
                title = 'Connections';
                break;
            default:
                title = params;
        }
        return title;
    };

    return (
        <div>
            <NavbarWithRouter navbarName={navbarName} />
            {children}
            <Footer />
        </div>
    );
};

export default AppContainer;
