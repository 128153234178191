import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEditShowMutation } from "../useMutation";

const EditShowModal = ({
  closeEditShowModal,
  editShowData,
  venuesToSelect,
  bandsToSelect,
  myBandId,
  refetch,
}) => {
  const initialVenue = venuesToSelect.filter(
    (item) => item["name"] === editShowData.showVenue
  );

  const [showDate, setShowDate] = useState(new Date(editShowData.showDate));
  const [venue, setVenue] = useState(initialVenue[0].id);
  const [otherBand, setOtherBand] = useState(editShowData.invitedBandId);

  let [editShow] = useEditShowMutation();

  const handleDateChange = (date) => {
    setShowDate(date);
  };

  const editShowFn = async () => {
    await editShow({
      variables: {
        where: {
          id: editShowData.where,
        },
        payload: {
          date: showDate.toISOString(),
          location: venue,
          bandIds: [otherBand],
        },
      },
    });

    closeEditShowModal();
    refetch();
  };

  const handleShowsSubmit = (evt) => {
    evt.preventDefault();
    editShowFn();
  };

  return (
    <div className="modal-box">
      <div className="modal-content">
        <span
          className="close"
          onClick={() => {
            closeEditShowModal();
          }}
        >
          &times;
        </span>
        <div className="modalTitle">Edit a show</div>
        <div>
          <form className="d-flex flex-column">
            <div className="inputs-container">
              <label className="mt-2">
                <span className="labelText">Date:</span>
                <DatePicker selected={showDate} onChange={handleDateChange} />
              </label>
              <label className="mt-2">
                <span className="labelText">Venue:</span>
                <select
                  value={venue}
                  onChange={(e) => setVenue(e.target.value)}
                  name="venue"
                  className="p-1"
                >
                  {venuesToSelect.map((venue, i) => {
                    return (
                      <option key={i} value={venue.id}>
                        {venue.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="mt-2">
                <span className="labelText">With:</span>
                <select
                  value={otherBand}
                  onChange={(e) => setOtherBand(e.target.value)}
                  name="otherband"
                  className="p-1"
                >
                  {bandsToSelect.map((band, i) => {
                    return (
                      <option key={i} value={band.id}>
                        {band.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <button
                type="button"
                className="mt-2 mr-5"
                id="go-button"
                onClick={handleShowsSubmit}
              >
                Save
              </button>
              <button type="button" className="mt-2 ml-5" id="go-button">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditShowModal;
