import React from 'react';
import { getIn } from 'formik';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Upload from '../../../components/UploadImage';
import * as Yup from 'yup';
import { useBandPageQuery } from '../../home/useQuery';
import { useCreateBandMutation } from '../useMutation';

const ArrayErrorMessage = ({ name }) => (
    <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? error : null;
        }}
    />
);

const validationSchema = Yup.object({
    name: Yup.string()
        .max(40, 'Must be 40 characters or less')
        .min(1, 'Must be at least 1 character')
        .required('Required'),
    about: Yup.string().max(60, 'Must be 60 characters or less'),
    zip: Yup.string()
        .max(5, 'Not a valid zip code')
        .min(5, 'Not a valid zip code')
        .required('Required'),
    members: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().min(
                1,
                'Did you mean to add something here? If not just remove this entry'
            ),
        })
    ),
    // genres: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().min(
    //             1,
    //             'Did you mean to add something here? If not just remove this entry'
    //         ),
    //     })
    // ),
    socialLinks: Yup.array().of(
        Yup.object().shape({
            url: Yup.string().url('not a valid link, sorry'),
        })
    ),
    audioLinks: Yup.array().of(
        Yup.object().shape({
            url: Yup.string().url('not a valid link, sorry'),
        })
    ),
});

const CreateBandForm = (props) => {
    const isCreate = props.location.pathname === '/create/';

    let { data, loading } = useBandPageQuery();
    let [createBand] = useCreateBandMutation()

    if (loading && !isCreate) {
        return <div>Loading...</div>;
    }

    const defaultValues = {
        name: '',
        about: '',
        zip: '',
        members: [{ name: '' }],
        // genres: [{ name: '' }],
        socialLinks: [{ url: '' }],
        audioLinks: [{ url: '' }],
        imageData: {},
        isRealSubmitButton: false,
    };

    if (!isCreate) {
        defaultValues.name = data.myBand.name;
        defaultValues.about = data.myBand.description;
        defaultValues.zip = data.myBand.zipCode;
        defaultValues.members = data.myBand.members;
        // defaultValues.genres = data.myBand.genres;
        defaultValues.audioLinks = data.myBand.audioLinks;
        defaultValues.socialLinks = data.myBand.socialLinks;
    }

    return (
        <div className="container-fluid" id="band-form-container">
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                if (!isCreate) {
                    console.log('Submit the edit form here');
                } else {
                    if (values.isRealSubmitButton) {
                        const imageObj = (({ asset_id, public_id }) => ({ asset_id, public_id }))(
                            values.imageData
                        );
                        const imageArr = [];
                        imageArr.push(imageObj);
                        const socialLinksArr = values.socialLinks.map(
                            (urlObj) => Object.values(urlObj)[0]
                        );
                        const audioLinksArr = values.audioLinks.map(
                            (urlObj) => Object.values(urlObj)[0]
                        );
                        const membersArr = values.members.map((urlObj) => Object.values(urlObj)[0]);

                        console.log('==variables===>>>', {
                            name: values.name,
                            zipCode: values.zip,
                            socialLinks: socialLinksArr,
                            audioLinks: audioLinksArr,
                            images: imageArr,
                            description: values.about,
                            members: membersArr,
                        });

                        await createBand({
                            variables: {
                                name: values.name,
                                zipCode: values.zip,
                                socialLinks: socialLinksArr,
                                audioLinks: audioLinksArr,
                                images: imageArr,
                                description: values.about,
                                members: membersArr,
                            },
                        });
                        setSubmitting(false);
                        props.history.push('/home/');
                    }
                }
            }}
        >
            {({
                values,
                errors,
                setFieldValue,
                isSubmitting,
                isValidating,
                isValid,
                dirty,
                handleSubmit,
            }) => (
                <Form>
                    <div className="container">
                        <div className="form-group">
                            <label htmlFor="inputName">Name</label>
                            <Field
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Name of the band"
                            />
                            <ErrorMessage name="name" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-8">
                                <label htmlFor="inputAbout">About</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    id="about"
                                    name="about"
                                    placeholder="What you're about"
                                />
                                <ErrorMessage name="about" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip">Zip</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    id="zip"
                                    name="zip"
                                    placeholder="Where you're at"
                                />
                                <ErrorMessage name="zip" />
                            </div>
                        </div>
                        <label htmlFor="members">Add bandmates</label>
                        <FieldArray name="members">
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.members.length > 0 &&
                                        values.members.map((member, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-10 bottomField">
                                                    <Field
                                                        name={`members.${index}.name`}
                                                        type="text"
                                                        placeholder="(if you have them - their names)"
                                                        className="form-control"
                                                    />
                                                    <ArrayErrorMessage
                                                        name={`members.${index}.name`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                </div>
                                                <div className="">
                                                    <button
                                                        disabled={index === 0}
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => push({ name: '' })}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray>
                        {/* <label htmlFor="genres" className="mt-3">Add genres</label> */}
                        {/* <FieldArray name="genres">
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.genres.length > 0 &&
                                        values.genres.map((genre, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-10 bottomField">
                                                    <Field
                                                        name={`genres.${index}.name`}
                                                        type="text"
                                                        placeholder="what you sound like"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage
                                                        name={`genres.${index}.name`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                </div>
                                                <div className="">
                                                    <button
                                                        disabled={index === 0}
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => push({ name: '' })}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray> */}
                        <label htmlFor="socialLinks" className="mt-3">Social Links</label>
                        <FieldArray name="socialLinks">
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.socialLinks.length > 0 &&
                                        values.socialLinks.map((socialLink, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-10 bottomField">
                                                    <Field
                                                        name={`socialLinks.${index}.url`}
                                                        type="text"
                                                        placeholder="Facebook, Insta etc"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage
                                                        name={`socialLinks.${index}.url`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                </div>
                                                <div className="">
                                                    <button
                                                        disabled={index === 0}
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => {
                                                            push({ url: '' });
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray>
                        <label htmlFor="audioLinks" className="mt-3">Audio Links</label>
                        <FieldArray name="audioLinks">
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.audioLinks.length > 0 &&
                                        values.audioLinks.map((audioLink, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-10 bottomField">
                                                    <Field
                                                        name={`audioLinks.${index}.url`}
                                                        type="text"
                                                        placeholder="Bandcamp, Soundcloud"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage
                                                        name={`audioLinks.${index}.url`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                </div>
                                                <div className="">
                                                    <button
                                                        disabled={index === 0}
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => push({ url: '' })}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray>
                        <Upload setFieldValue={setFieldValue} />
                        <button
                            id="form-submit"
                            disabled={!(isValid && dirty)}
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => {
                                console.log('values and errors: ', values, errors);
                                setFieldValue('isRealSubmitButton', true);
                                handleSubmit(e);
                            }}
                        >
                            {!isCreate ? 'Edit band' : 'Create band'}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
        </div>
    );
};

export default CreateBandForm;
