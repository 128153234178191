const showsConverter = (datefromServer) => {
  const monthConverter = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }
  const dayConverter = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }
  let newDate = new Date(datefromServer);
  let formattedDate;
  const dayOfWeek = newDate.getDay();
  const month = newDate.getMonth();
  const dayOfMonth = newDate.getDate();
  formattedDate = dayConverter[dayOfWeek] + ', ' + monthConverter[month] + ' ' + dayOfMonth;
  return formattedDate;
}

const availConverter = (thisDate) => {

}

export { showsConverter, availConverter };
