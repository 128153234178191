import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';


const searchClient = algoliasearch('LY78M11XXK', '1f9be49cd277d2f7278f023a66e04137');

// const algSearchClient = {
//     appId: "LY78M11XXK",
//     apiKey: "1f9be49cd277d2f7278f023a66e04137"
// }

function FoundBand({ hit }) {
    console.log('hitobject-->', hit)
    return (
        <div style={{ marginTop: '10px' }}>
            <div className="hit-name row result">
                <div className="col-2"></div>
                <div className="col name">
                    <Link to={`/profile/${hit.id}`}>
                        <Highlight attribute="name" hit={hit} />
                    </Link>
                </div>
                <div className="col genre-middle">{hit.genre}</div>
                <div className="col-2"></div>
            </div>
        </div>
    );
}

const DiscoverSearch = () => (
  <InstantSearch searchClient={searchClient} indexName="dev_BANDS">
    <SearchBox />
    <Hits hitComponent={FoundBand} />
  </InstantSearch>
);

export default DiscoverSearch;