import React from 'react';
import { useHistory } from 'react-router';
import SlidingMenu from './Menu';

const Navbar = () => {
    let history = useHistory();
    return (
        <nav className="navbar header row">
            <div className="col-9">
                <span id="signedinas"></span>
            </div>
            <div className="col">
                <SlidingMenu history={history} />
            </div>
        </nav>
    );
};

export default Navbar;
