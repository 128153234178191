import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAddNewShowMutation } from '../useMutation';

const ShowsModal = ({ closeShowsModal, myBandId, bandsToSelect, venuesToSelect, refetch }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [venue, setVenue] = useState(venuesToSelect[0].id);
    const [otherBand, setOtherBand] = useState(bandsToSelect[0].id);

    let [addNewShow] = useAddNewShowMutation();

    const handleShowsSubmit = (evt) => {
        evt.preventDefault();
        addNewShowFn();
    };

    const handleDateChange = (date) => {
        setStartDate(date);
    };

    const addNewShowFn = async () => {
        await addNewShow({
            variables: {
                schedulingBand: myBandId,
                startDate,
                venue,
                otherBand,
            },
        });

        closeShowsModal();
        refetch();
    };

    return (
        <div className="modal-box">
            <div className="modal-content">
                <span
                    className="close"
                    onClick={() => {
                        closeShowsModal();
                        refetch();
                    }}
                >
                    &times;
                </span>
                <div className="modalTitle">Create a show</div>
                <div>
                    <form className="d-flex flex-column">
                        <div className="inputs-container">
                        <label className="mt-2">
                            <span className="labelText">Date:</span>
                            <DatePicker selected={startDate} onChange={handleDateChange} />
                        </label>
                        <label className="mt-2">
                            <span className="labelText">Venue:</span>
                            <select
                                value={venue}
                                onChange={(e) => setVenue(e.target.value)}
                                name="venue"
                                className="p-1"
                            >
                                {venuesToSelect.map((venue, i) => {
                                    return <option key={i} value={venue.id}>{venue.name}</option>;
                                })}
                            </select>
                        </label>
                        <label className="mt-2">
                            <span className="labelText">With:</span>
                            <select
                                value={otherBand}
                                onChange={(e) => setOtherBand(e.target.value)}
                                name="otherBand"
                                className="customSelect p-1"
                            >
                                {bandsToSelect.map((band, i) => {
                                    return <option key={i} value={band.id}>{band.name}</option>;
                                })}
                            </select>
                        </label>
                        </div>
                        <button type="submit" value="Submit" className="mt-2 mx-auto" id="go-button" onClick={handleShowsSubmit}>
                            Add Shows
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ShowsModal;
