import React from 'react';
import Item from './Item';

const List = (props) => {
    return (
        <div className="container">
            <div className="col">
                <div className="row" id="section-header">
                    Shows
                </div>
                {props.isHome ? (
                    <div className="row">
                        <button onClick={props.openShowsModal} className="leftBox__button font-weight-bold">Add Show</button>
                    </div>
                ) : null}
                {Array.isArray(props.shows) ? (
                    <div>
                        {props.shows.map((show, i) => {
                            return (
                                <div className="row" key={i}>
                                    <Item
                                        date={show.date}
                                        location={show.location}
                                        ticket={show.ticket}
                                        openEditShowModal={props.openEditShowModal}
                                        where={show.id}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        {props.isHome ? (
                            <div className="row">You have no scheduled shows</div>
                        ) : (
                            <div className="row">No scheduled shows</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default List;
