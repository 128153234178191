import gql from 'graphql-tag';

export const createBandMutation = gql`
    mutation(
        $name: String!
        $genres: [ID!]
        $zipCode: String!
        $description: String!
        $members: [String!]
        $socialLinks: [String!]
        $audioLinks: [String!]
        $images: [ImageInput]
    ) {
        newBand(
            name: $name
            genres: $genres
            audioLinks: $audioLinks
            socialLinks: $socialLinks
            members: $members
            imageData: $images
            description: $description
            zipCode: $zipCode
        ) {
            id
        }
    }
`;
