import gql from 'graphql-tag';

export const GetConnections = gql`
    query GetConnections {
        myBand {
            name
            id
            description
            email
            zipCode
            members
            genres {
                name
            }
            connections {
                name
                id
                audioLinks
                genres {
                    name
                }
                imageData {
                    accessId
                }
            }
        }
    }
`;

export const RemoveConnection = gql`
    mutation removeConnection($id: String!, $connectionId: String!) {
        removeConnection(id: $id, connectionId: $connectionId) {
            id
        }
    }
`;
