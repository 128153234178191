import React from 'react';
import AvailabilityList from '../../availabilityList/components/AvailabilityList';
import ConnectRequests from '../../request/components/ConnectRequests';
import List from './List';

const LeftProfBar = (props) => {
    return (
        <div className="leftBox">
            <div>
                <div className="sectionBox">
                    <ConnectRequests requests={props.requests} myId={props.myId} />
                </div>
                <div className="sectionBox">
                    <List
                        openShowsModal={props.openShowsModal}
                        closeShowsModal={props.closeShowsModal}
                        openEditShowModal={props.openEditShowModal}
                        isHome={true}
                        shows={props.shows}
                        refetch={props.refetch}
                    />
                </div>
                <div className="sectionBox">
                    <AvailabilityList
                        openAvailModal={props.openAvailModal}
                        closeAvailModal={props.closeAvailModal}
                        isHome={true}
                        availability={props.availability}
                        myId={props.myId}
                        refetch={props.refetch}
                    />
                </div>
            </div>
        </div>
    );
};

export default LeftProfBar;
