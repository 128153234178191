import React from 'react';
import { showsConverter } from '../../../utils';

const AvailableMonth = ({ time }) => {
    return (
        <div className="list-item">
            <div>{showsConverter(time)}</div>
        </div>
    );
};

export default AvailableMonth;
