import gql from 'graphql-tag';

export const BandPageData = gql`
    query BandPageData {
        myBands {
            name
            id
            description
            email
            zipCode
            audioLinks {
              url
            }
            socialLinks {
              url
            }
            members {
              name
            }
            imageData {
              assetId
              publicId
            }
            genres {
              name
            }
            shows {
              id
              date
              location {
                name
              }
              ticket {
                id
                name
              }
            }
            availability {
              id
              start_time
              end_time
            }
            requests {
              id
              initiator {
                name
                id
              }
              hasResponse
              isAccepted
            }
          }
    }
`;

export const OtherBands = gql`
    query {
        bands {
            id
            name
        }
    }
`;

export const AllVenues = gql`
    query {
        venues {
            id
            name
        }
    }
`;
