import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import { showsConverter } from '../../../utils';

const Item = (props) => {
    return (
        <div className="list-item">
            <div className="list-item-wrapper">
            <div>{showsConverter(props.date)}</div>
            <div>
                at&nbsp;
                <span id="connect-band-name">{props.location.name}</span>
            </div>
            <div>
                with&nbsp;
                <span id="connect-band-name">{props.ticket[0].name}</span>
            </div>
            </div>
            <button className="leftBox__button align-self-center font-weight-bold" onClick={() => props.openEditShowModal(props.where, props.ticket[0].id, props.location.name, props.date)}>
                <FontAwesomeIcon className="editIcon" icon={faEdit} />
                Edit Show
            </button>
        </div>
    );
};

export default Item;
