import { useMutation } from 'react-apollo';
import { removeAvailability } from './gql';

export const useRemoveAvailabilityMutation = (dateId, band) =>
    useMutation(removeAvailability, {
        variables: {
            dateId,
            band,
        },
    });
