import gql from 'graphql-tag';

export const REJECT_REQUEST = gql`
    mutation rejectConnectRequest($requestId: String!) {
        rejectConnectRequest(requestId: $requestId) {
            id
        }
    }
`;

export const ACCEPT_REQUEST = gql`
    mutation acceptConnectRequest($initiator: String!, $recipient: String!) {
        acceptConnectRequest(initiator: $initiator, recipient: $recipient) {
            response
        }
    }
`;
