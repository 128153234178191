import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';

const cloudName = 'dlhbtxwlg';
const uploadPreset = 'dajvpucs';

const Upload = (props) => {
    const [file, setFile] = useState('');
    const [fileDropped, setFileDropped] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    const onDrop = async (files) => {
        setFile(files[0]);
        setFileDropped(true);
    };

    const submit = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', uploadPreset);

        const response = await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
        );
        props.setFieldValue('imageData', response.data);
        setFile(null);
        setFileUploaded(true);
    };

    return (
        <div className="mt-3">
            <label htmlFor="inputName">Header Image</label>
            {fileUploaded ? (
                <div className="row">Your upload was successful!</div>
            ) : (
                <div className="row">
                    <div className="col">
                        <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Click here to add an image for your band</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="col">
                        <button
                            disabled={!fileDropped}
                            className="btn btn-primary"
                            onClick={submit}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Upload;
