import React from 'react';

const Footer = () => {
    return (
        <div id="footer">
            <div>Copyright © 2021 Staged Productions LLC</div>
            <div>All rights reserved</div>
        </div>
    );
};

export default Footer;
