import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

const SlidingMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const logOut = () => {
        localStorage.removeItem('token');
        props.history.push(`/login`);
    };

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen);
    };

    const closeMenue = () => {
        setMenuOpen(false);
    };

    return (
        <Menu right isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
            <div>
                {' '}
                <Link
                    to={'/home'}
                    className="menu-item--small"
                    onClick={() => {
                        closeMenue();
                    }}
                >
                    Home
                </Link>
            </div>
            <div>
                {' '}
                <Link
                    to="/discover"
                    className="menu-item--small"
                    onClick={() => {
                        closeMenue();
                    }}
                >
                    Discover
                </Link>
            </div>
            <div>
                {' '}
                <Link
                    to="/connections"
                    className="menu-item--small"
                    onClick={() => {
                        closeMenue();
                    }}
                >
                    Connections
                </Link>
            </div>
            <button
                type="submit"
                className="menu-item--small"
                onClick={() => {
                    logOut();
                }}
            >
                Log out
            </button>
        </Menu>
    );
};

export default SlidingMenu;
