import { useMutation } from 'react-apollo';
import { ACCEPT_REQUEST, REJECT_REQUEST } from './gql';

export const useAcceptConnectionMutation = (initiator, recipient) =>
    useMutation(ACCEPT_REQUEST, {
        variables: {
            initiator,
            recipient,
        },
    });

export const useRejectConnectionMutation = (requestId) =>
    useMutation(REJECT_REQUEST, {
        variables: {
            requestId,
        },
    });
