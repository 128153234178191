import gql from 'graphql-tag';

export const addNewShowMutation = gql`
    mutation addNewShow(
        $schedulingBand: ID!
        $otherBand: [ID!]
        $venue: ID
        $startDate: DateTime
    ) {
        newShow(
            scheduler: $schedulingBand
            ticket: $otherBand
            venue: $venue
            date: $startDate
        ) {
            id
        }
    }
`;

export const addNewDateMutation = gql`
    mutation addBandAvailability($date: DateTime!, $band: String!) {
        addBandAvailability(date: $date, band: $band) {
            id
        }
    }
`;

export const editShowMutation = gql`
    mutation editExistingShow(
        $where: EditShowWhereInput!
        $payload: EditShowPayloadInput!
    ) {
        editShow(
            where: $where
            payload: $payload
        ) {
            id
        }
    }
`;