import React from 'react';
import { useRemoveAvailabilityMutation } from '../useMutation';
import AvailableMonth from './AvailableMonth';

const AvailabilityList = ({ myId, refetch, openAvailModal, isHome, availability }) => {
    const [removeAvailability] = useRemoveAvailabilityMutation();

    const handleRemove = (date) => {
        const dateId = date.id;
        const band = myId;
        removeAvailability({
            variables: {
                dateId,
                band,
            },
        });
        refetch();
    };

    return (
        <div className="container">
            <div className="col">
                <div className="row" id="section-header">
                    Availability
                </div>
                {isHome ? (
                    <div className="row">
                        <button onClick={openAvailModal} className="leftBox__button font-weight-bold">Add Availability</button>
                    </div>
                ) : null}
                <div id="connect-band-name" className="row mt-2">Upcoming dates:</div>
                {Array.isArray(availability) ? (
                    <div>
                        {availability.map((date) => {
                            return (
                                <div className="row">
                                    <AvailableMonth time={date.time} />
                                    <button onClick={() => handleRemove(date)}>x</button>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        {isHome ? (
                            <div className="row">You have no scheduled availability</div>
                        ) : (
                            <div className="row">No scheduled availability</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AvailabilityList;
