import React, { useState } from 'react';
import { useAcceptConnectionMutation, useRejectConnectionMutation } from '../useMutation';

const ConReq = ({ request, myId }) => {
    const [hasResponse, setHasResponse] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);

    const [acceptRequest] = useAcceptConnectionMutation();
    const [rejectRequest] = useRejectConnectionMutation();

    const handleAccept = () => {
        setHasResponse(true);
        setIsAccepted(true);

        const initiatorId = request.initiator.id;
        const recipientId = myId;

        acceptRequest({
            variables: { recipient: recipientId, initiator: initiatorId },
        });
    };

    const handleReject = () => {
        setHasResponse(true);
        setIsAccepted(false);
        const requestId = request.id;

        rejectRequest({ variables: { requestId } });
    };
    return (
        <div className="list-item">
            <div>
                {!hasResponse ? (
                    <div>
                        <div>
                            <span id="connect-band-name">{request.initiator.name}&nbsp;</span>
                            <span>would like to connect!</span>
                            <div>
                                <button onClick={handleAccept}>Y</button>
                            </div>
                            <div>
                                <button onClick={handleReject}>N</button>
                            </div>
                        </div>
                        <div id="connect-timestamp">Four hours ago</div>
                    </div>
                ) : (
                    <div>
                        {isAccepted ? (
                            <div>
                                <span id="connect-band-name">{request.initiator.name}&nbsp;</span>
                                <span>is now connected!</span>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConReq;
