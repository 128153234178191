import React, { useState } from 'react';
import { AUTH_TOKEN } from '../../../constants';
import { Redirect, useHistory } from 'react-router-dom';
import { useSignupMutation, useLoginMutation } from '../useMutation';

const SignupLogin = () => {
    const [login, setLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const history = useHistory();
    const [signup] = useSignupMutation();
    const [loginMutation] = useLoginMutation();

    const _confirm = async () => {
        let token;
        let currentUserId;
        if (login) {
            const result = await loginMutation({
                variables: {
                    email,
                    password,
                },
            });
            token = result.data.login.token;
            currentUserId = result.data.login.user.id;
            _saveUserData(token, currentUserId);
            history.push('/home/');
        } else {
            const result = await signup({
                variables: {
                    name,
                    email,
                    password,
                },
            });
            currentUserId = result.data.signup.user.id;
            token = result.data.signup.token;
            _saveUserData(token, currentUserId);
            history.push('/create/');
        }

        setRedirectToReferrer(true);
    };

    const _saveUserData = (token, currentUserId) => {
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem('current-user', currentUserId);
    };

    let { from } = history.location.state || { from: { pathname: '/' } };

    if (redirectToReferrer) return <Redirect to={from} />;
    return (
        <div className="container-fluid" id="login-box">
            <div className="login-text container">
                {!login ? (
                    <div>
                        <div className="row">
                            <h3>Register Account</h3>
                        </div>
                        <div className="row mt-3">name</div>
                        <div className="row mt-1">
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                className="col"
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="row">
                            <h3>Sign in</h3>
                        </div>
                    </div>
                )}
                <div className="row mt-3">email</div>
                <div className="row mt-1">
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        className="col"
                    />
                </div>
                <div className="row mt-3">password</div>
                <div className="row mt-1">
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className="col"
                    />
                </div>
            </div>
            <div className="mt-4" id="signup-options">
                <div className="row">
                    <div className="col go-bt-cntner">
                        <button id="go-button" type="submit" onClick={() => _confirm()}>
                            GO
                        </button>
                    </div>
                </div>
                {login ? (
                    <div className="mt-3">
                        <div className="row">
                            <div className="col" id="no-account">
                                <span id="no-account">No account?&nbsp;</span>
                                <span id="request-invite" className="font-weight-bold">Request an invite</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col" id="no-account">
                                <span id="no-account">Already invited?&nbsp;</span>
                                <span id="request-invite" className="font-weight-bold" onClick={() => setLogin(!login)}>
                                    Register your account
                                </span>
                            </div>
                        </div>
                        <div className="row mt-2">
                         <div className="col font-weight-bold" id="forgot-pw">
                            Forgot password?
                         </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="row mt-3 text-center">
                            <div className="col" id="no-account">
                                <span id="no-account">Already have an account?&nbsp;</span>
                                <span id="request-invite" className="font-weight-bold" onClick={() => setLogin(!login)}>
                                    Log in here
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignupLogin;
