import React from 'react';
import profileIcon from '../../../assets/ProfileIcon.png';
import { Link } from 'react-router-dom';
import { useRemoveConnectionMutation } from '../useMutation';
import { useGetConnectionsQuery } from '../useQuery';

const Connections = () => {
    const { loading, error, data, refetch } = useGetConnectionsQuery();
    const [RemoveConnection] = useRemoveConnectionMutation();

    const handleRemove = (myId, connectionId) => {
        RemoveConnection(
            { variables: { id: myId, connectionId } },
            { refetchQueries: [{ query: 'GetConnections' }] }
        );
    };
        console.log('data in connections---> ', data);
    // let { myBand } = data;

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error getting the data!</p>;
    }

    let numConnections = 0;
    if (data.myBand.connections.length >0) {
        numConnections = data.myBand.connections.length;
        let myBandId;

        if (data.myBand) {
            myBandId = data.myBand.id;
        }

        return (
            <div className="connectionsContainer">
                <div id="connectionsHeaderBar">
                    <span className="profile-icon-top">
                        <img src={profileIcon} alt="" />
                    </span>
                    {numConnections} Connection(s)
                </div>
                <div className="container">
                    {data.myBand.connections.map((band) => {
                        return (
                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col connectName connectResult">
                                    <Link to={`/profile/${band.id}`}>{band.name}</Link>
                                </div>
                                <div className="col removelink connectResult">
                                    <button
                                        onClick={() => {
                                            handleRemove(myBandId, band.id);
                                            refetch();
                                        }}
                                    >
                                        Remove Connection
                                    </button>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    } else {
        return (
            <div className="connectionsContainer d-flex justify-content-center p-3">
                <div id="connectionsHeaderBar" className="font-weight-bold">You have no connections yet!</div>
            </div>
        );
    }
};

export default Connections;
