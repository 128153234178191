import gql from 'graphql-tag';

export const SIGNUP_MUTATION = gql`
    mutation($email: String!, $password: String!, $name: String!) {
        signup(email: $email, name: $name, password: $password) {
            user {
                id
                name
            }
            token
        }
    }
`;

export const LOGIN_MUTATION = gql`
    mutation($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
                name
                password
                email
            }
            token
        }
    }
`;
